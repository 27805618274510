import * as THREE from 'three'
import { useRef, useState, useEffect } from 'react'
import { Canvas, useFrame,useThree } from '@react-three/fiber'
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { Image, Float, ScrollControls, useScroll, useTexture, Text , useCursor , CameraControls } from '@react-three/drei'
import { easing } from 'maath'
import { suspend } from 'suspend-react'
import './util'
import { isShowModalState , isShowModalPosition } from './ui.state';
import {isBrowser,isMobile} from "react-device-detect";
import Modal from "./Modal"
import PortfolioData from "./json/portfolioData.json";
import Logo from './svg/logo.svg';
import { ReactComponent as Email } from './svg/email.svg';

export default function App() {

  const regular = import('./fonts/NotoSansKR-Regular.ttf')
  const medium = import('./fonts/NotoSansKR-Medium.ttf')
  const portfolioLength = PortfolioData.length;
  const isShowModal = useRecoilValue(isShowModalState);
  const setModal = useSetRecoilState(isShowModalState);
  const setModalposition = useSetRecoilState(isShowModalPosition);





  function Rig(props) {
    const ref = useRef()
    const scroll = useScroll()
    useFrame((state, delta) => {
      ref.current.rotation.y = -scroll.offset * (Math.PI * 2)
      state.events.update() // Raycasts every frame rather than on pointer-move
      easing.damp3(state.camera.position, [-state.pointer.x * 1, state.pointer.y + 0.1, portfolioLength/3.2], 0.8, delta)
      // easing.damp3(state.camera.position, [-state.pointer.x * 0.5, 0, portfolioLength/3.2], 1.2, delta)
      state.camera.lookAt(0, 0, 0)
    })
    return <group ref={ref} {...props}
     />
  }


  function Carousel({ radius = portfolioLength / 5, ...props }) {

    return Array.from({ length: portfolioLength }, (_, i) => (
      <group {...props}
        key={i}
      >
          <Text
            font={suspend(medium).default}
            color="#000"
            fontSize={0.045}
            anchorY="top"
            anchorX="center"
            lineHeight={27}
            // position={[0, Math.sin((i / count) * Math.PI * 2) * radius, Math.cos((i / count) * Math.PI * 2) * radius,]}
            // rotation={[ Math.PI*2 + (i / count*9) * Math.PI*2, 0, 0]}
            position={[Math.sin((i / portfolioLength) * Math.PI * 2) * radius, 0, Math.cos((i / portfolioLength) * Math.PI * 2) * radius,]}
            rotation={[0, Math.PI * 2 + (i / portfolioLength) * Math.PI * 2, 0]}
            material-toneMapped={true}
          >
            {PortfolioData[i].title}
          </Text>

          <Text
            font={suspend(regular).default}
            color="#000"
            fontSize={0.03}
            anchorY="top"
            anchorX="center"
            lineHeight={44.5}
            // position={[0, Math.sin((i / count) * Math.PI * 2) * radius, Math.cos((i / count) * Math.PI * 2) * radius,]}
            // rotation={[ Math.PI*2 + (i / count*9) * Math.PI*2, 0, 0]}

            position={[Math.sin((i / portfolioLength) * Math.PI * 2) * radius, 0, Math.cos((i / portfolioLength) * Math.PI * 2) * radius,]}
            rotation={[0, Math.PI * 2 + (i / portfolioLength) * Math.PI * 2, 0]}
            material-toneMapped={true}
          >
            {PortfolioData[i].date}
          </Text>
          <Card
            // url={`/img${Math.floor(i % 10) + 1}_.jpg`}
            // url={`/img${i+1}.png`}
            url={PortfolioData[i].thumbUrl}


            // position={[0, Math.sin((i / count) * Math.PI * 2) * radius, Math.cos((i / count) * Math.PI * 2) * radius,]}
            // rotation={[ Math.PI*2 + (i / count*9) * Math.PI*2, 0, 0]}

            position={[Math.sin((i / portfolioLength) * Math.PI * 2) * radius, 0, Math.cos((i / portfolioLength) * Math.PI * 2) * radius,]}
            rotation={[0, (Math.PI * 2 + (i / portfolioLength) * Math.PI * 2), 0]}

            onClick={(e) => (
              e.stopPropagation(),
              setModal(true),
              setModalposition(PortfolioData[i].id)
            )
            }
          />

      </group>  
    ))
  }
  
function Card({ url, ...props }) {
  const ref = useRef()
  const [hovered, hover] = useState(false)
  const pointerOver = (e) => (e.stopPropagation(), hover(true))
  const pointerOut = () => hover(false)
  let test1 = 0.001

  useCursor(hovered)

  useFrame((state, delta) => {
    easing.damp3(ref.current.scale, hovered ? 1.05 : 1, 0.1, delta)
    easing.damp(ref.current.material, 'radius', hovered ? 0.03 : 0.02, 0.02, delta)
    easing.damp(ref.current.material, 'zoom', hovered ? 1.1 : 1, 0.2, delta)
  })

  return (
    <Image ref={ref} url={url} transparent side={THREE.DoubleSide} onPointerOver={pointerOver} onPointerOut={pointerOut} 
      {...props}>
      <bentPlaneGeometry args={[0.00001, 0.76, 1.05, 1, 1]} />
    </Image>
  )
}


  return (
    <>
      {isShowModal &&
        <Modal />
      }
      {isBrowser?   <div className='GuideWrap'>
        <div class="scroll-downs">
          <div class="mousey">
            <div class="scroller"></div>
          </div>
        </div>
        <p>Scroll down to move</p>
      </div> : "" }
      <div className="footerWrap">
        <img src={Logo} />
        <h5>nzmotion</h5>
        <h6>© 2024 nzmotion All rights reserved</h6>
        <a href="mailto:nzmotion@naver.com"><Email className="email" /></a>
      </div>
      <Canvas camera={{ position: [0, 0, -2], fov: 50 }}>
        <ambientLight intensity={1} />
        <fog attach="fog" args={['#f2f2f2', 0, 15]} />
        <Float rotationIntensity={0.01} floatIntensity={0.01} speed={1}>
            <ScrollControls horizontal pages={isBrowser? portfolioLength/5 : portfolioLength/2  } infinite>
              <Rig>
                <Carousel />
              </Rig>
            </ScrollControls>
          </Float>
    </Canvas >
    </>
  )
}








